import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import ContactForm from '../components/form/Contact';
//import NewAddressSection from '../components/section/NewAddress';

const ContactPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/contact-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Contact | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      pageDescription={`Vous avez un projet ? Contactez ${query.site.siteMetadata.title}, notre équipe met tout en œuvre afin de réaliser vos projets de plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure.`}
      pageKeywords="plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure, isolation extérieure"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Contactez-nous</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Contact</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section id="contact" className="bg-white-f4">
        <div className="container">
          <div className="section-content p-50 bg-white">
            <div className="row">
              <div className="col-lg-6">
                <div className="item-inner">
                  <div className="item-content">
                    <div className="tm-element-holder">
                      <div className="tm-element-holder-wrapper">
                        <div className="tm-element-holder-content">
                          <div className="tm-sc">
                            <div className="title-wrapper">
                              <h2 className="title line-height-1.3"> <span>Vous avez une </span> <span className="text-theme-colored1">question</span> ?</h2>
                              <div className="title-seperator-line"></div>
                            </div>
                          </div>
                          <p>Contactez-nous pour voir comment nous pouvons vous aider dans votre projet.</p>
                          <div className="row">
                            {query.site.siteMetadata.addressGoogleDirection &&
                              <div className="col-12">
                                <h5><i className="bi bi-geo-alt me-1"></i>Adresse</h5>
                                <p>
                                  <a href={query.site.siteMetadata.addressGoogleDirection} target="_blank" rel="noopener noreferrer" aria-label="address direction">
                                    {query.site.siteMetadata.addressStreet} {query.site.siteMetadata.addressPostalCode} {query.site.siteMetadata.addressCity}
                                  </a>
                                </p>
                              </div>
                            }
                            {query.site.siteMetadata.telephone &&
                              <div className="col-12">
                                <h5><i className="bi bi-telephone me-1"></i>Téléphone</h5>
                                <p><a href={"tel:" + query.site.siteMetadata.telephoneIndex} rel="noreferrer" aria-label="téléphone"> {query.site.siteMetadata.telephone}</a></p>
                              </div>
                            }
                            <div className="col-12">
                              <h5>Suivez-nous</h5>
                              <ul className="styled-icons icon-dark icon-rounded icon-theme-colored1">
                                {query.site.siteMetadata.socialNetworks.facebook &&
                                  <li key="facebook"><a className="social-link" href={query.site.siteMetadata.socialNetworks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="bi bi-facebook"></i></a></li>
                                }
                                {query.site.siteMetadata.socialNetworks.instagram &&
                                  <li key="instagram"><a className="social-link" href={query.site.siteMetadata.socialNetworks.instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram"><i className="bi bi-instagram"></i></a></li>
                                }
                                {query.site.siteMetadata.socialNetworks.linkedIn &&
                                  <li key="linkedin"><a className="social-link" href={query.site.siteMetadata.socialNetworks.linkedIn} target="_blank" rel="noopener noreferrer" aria-label="linkedin"><i className="bi bi-linkedin"></i></a></li>
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<NewAddressSection site={query.site} sectionStyle="1" />*/}
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12">
              <iframe
                src="https://maps.google.com/maps?q=10%20Rue%20Pierre%20Maillot,%2042120%20Le%20Coteau%20pcc&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="2600"
                height="600"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                title={query.site.siteMetadata.title}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
